import React from "react";
import "./CardConteudo.scss";

import {
	MDBRow,MDBCol,MDBTable, MDBTableHead, MDBTableBody
} from "mdbreact";

import PropTypes from "prop-types";

/**
 * 	Card de valores.
 *  @param {string} bgColor: "string", style para backgound.
 *  @param {string} amount: "string", quantidade de parcelas.
 *  @param {string} value: "string", valor separado por virgula.
 */
export default function CardConteudo({
	title,
	description,
	borderColor,
	horario = null,
	img = null,
	imgLeft = false,
	table = false,
}) {
	CardConteudo.propTypes = {
		title: PropTypes.string,
		horario: PropTypes.string,
		description: PropTypes.string,
		borderColor: PropTypes.string,
		img: PropTypes.string,
		imgLeft: PropTypes.bool,
		table: PropTypes.bool,
	};

	return (
		<>
			<MDBRow className="my-2 px-3">
				<MDBCol className={borderColor}>
					<MDBRow className="d-flex p-0 m-0">
						{imgLeft ? (
							<>
								{img && (
									<MDBCol
										col={12}
										md={4}
										className="p-0 text-center order-1 order-md-0 "
									>
										<img src={img} className="img-fluid" />{" "}
										{/* Tamanho correto da img 405x306 */}
									</MDBCol>
								)}
								<MDBCol
									col={12}
									md={img ? 8 : 12}
									className="p-4 m-0 order-1 order-md-0 text-center text-md-left"
								>
									<span className="h5 font-weight-bold">{title}</span>
									{horario != null && (
										<p className="fgsecondary-gradient mt-2 mb-0 font-weight-bold">
											{horario}
										</p>
									)}
									<p className="text-justify mt-1">
										//{" "}
										<span dangerouslySetInnerHTML={{ __html: description.replace('// ','') }} />
									</p>
								</MDBCol>
								
							</>
						) : (
							<>
								<MDBCol
									col={12}
									md={img ? 8 : 12}
									className="p-4 m-0 order-0 text-center text-md-left"
								>
									<span className="h5 font-weight-bold">{title}</span>
									{horario != null && (
										<p className="fgsecondary-gradient mt-2 mb-0 font-weight-bold">
											{horario}
										</p>
									)}
									<p className="text-justify mt-2 mb-0">
										//{" "}
										<span dangerouslySetInnerHTML={{ __html: description.replace('// ','') }} />
									</p>
								</MDBCol>
								
								{table && (
									<MDBCol className="pl-4 pr-4 pb-4 m-0 order-0 align-items-center text-md-left">
										<MDBTable bordered>
											<MDBTableBody>
												<tr>
												<th scope='row'>Cursos de graduação presencial Flex – matrizes curriculares: 2021/01, 2023/01 e 2024/01. Necessária a contratação mínima de 02 módulos para receber o desconto.</th>
												<td className="text-center">20% de desconto</td>
												</tr>
												<tr> 
												<th scope='row'>Cursos de graduação digital - EAD</th>
												<td className="text-center">10% de desconto</td>
												</tr>
											</MDBTableBody>
											</MDBTable>
											<div className="letraPequena pt-2">O desconto é válido somente para matrícula efetuada no semestre
											imediatamente posterior a conclusão do Ensino Médio;</div>
											<div className="letraPequena">O desconto não é válido para Medicina, Medicina Veterinária e Odontologia;</div>
											<div className="letraPequena">O desconto deve ser solicitado pelo e-mail: <a href="mailto:financeiro@feevale.br">financeiro@feevale.br</a></div>
									</MDBCol>
								)}
							</>
						)}
					</MDBRow>
				</MDBCol>
			</MDBRow>
		</>
	);
}
