import * as React from "react";
import Layout from "../Layout/MasterLayout/MasterLayout";
import Button from "../components/Button/Button";

import {
	MDBContainer,
	MDBRow,
	MDBCol,
} from "mdbreact";
import BannerCarousel from "../components/BannerCarousel/BannerCarousel";
import SEO from "../components/SEO/SEO";

import imgBeneficios from "../assets/images/beneficios/beneficios.png";

import CardConteudo from "../components/CardConteudoDesconto/CardConteudo";

export default function Beneficios() {
	return (
		<Layout>
			<SEO title="Benefícios" lang="pt-br"/>
			<MDBContainer>
				<MDBRow>
					<MDBCol
						col={12}
						md={6}
						className="order-1 order-sm-0 my-auto"
					>
						<MDBRow>
							<MDBCol
								col={12}
								md={12}
							>
								<h1 className="pageTitle fgprimary-gradient py-5">benefícios</h1>
							</MDBCol>
							<MDBCol>
								<h2 className="fgprimary-gradient">matrículas e rematrículas</h2>
							</MDBCol>
							<MDBCol className="pb-2 pt-4 py-md-4" md={12}>
								<Button
									type="institucional"
									to="/matriculas"
									text="Quero estudar na Escola de Aplicação Feevale"
								/>
							</MDBCol>
							<MDBCol md={12}>
								<Button
									type="institucional"
									to="/rematriculas"
									text="Quero fazer a rematrícula na Escola de Aplicação Feevale"
								/>
							</MDBCol>
						</MDBRow>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgBeneficios} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="text-center">
						<BannerCarousel bannerKey="58028DE1-19C1-4AD7-8B1E-A15648C57538" bannerLength="1" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h2 className="fgprimary-gradient h1 py-4 text-center">
							confira os descontos
						</h2>
					</MDBCol>
				</MDBRow>
				<CardConteudo
						key={1}
						title={"Ingressos/transferências"}
					
						description={'Alunos que ingressarem por transferência em todos os níveis de ensino da Escola de Aplicação, terão direito ao desconto de 20% aplicado no valor das duas primeiras mensalidades. São também contemplados com o desconto de 20% aplicado para as duas primeiras mensalidades, os alunos que ingressarem nas séries iniciais da Educação Infantil.'}
						borderColor={"institucional"}
				/>
				<CardConteudo
						key={2}
						title={"Indicações"}
					
						description={'Alunos matriculados na Escola de Aplicação receberão um desconto de 20% aplicado no valor de duas mensalidades ao indicar um novo aluno, condicionado a efetivação da matrícula do novo aluno.'}
						borderColor={"institucional"}
				/>
				<CardConteudo
						key={2}
						title={"Desconto cursos técnicos"}
					
						description={'Alunos matriculados no Ensino Médio na Escola de Aplicação e que, efetuarem a matrícula nos cursos técnicos da Escola de Aplicação, receberão um desconto de 20% aplicado nas mensalidades do curso técnico. Terão direito ao desconto os alunos que ingressarem no primeiro ano do Ensino Médio a partir de 2025. Este desconto fica condicionado a permanência e manutenção da matrícula do aluno no Ensino Médio, não sendo aplicado nos casos de solicitação de transferência, conclusão do Ensino Médio, etc.'}
						borderColor={"institucional"}
				/>
				<CardConteudo
						key={3}
						title={"Desconto nos cursos de graduação"}
					
						description={'Alunos da Escola de Aplicação Feevale, concluintes do Ensino Médio, que efetuarem sua matrícula em cursos de graduação da Universidade Feevale, terão direito a desconto no primeiro semestre conforme os seguintes critérios:'}
						borderColor={"institucional"}
						table = {true}
				/>
				<MDBRow>
					<MDBCol col={12} md={6} className="py-3 pb-md-0 pt-md-3">
						<Button
							type="institucional"
							to="https://way.feevale.br/cursos/"
							text="Conheça os nossos cursos de graduação"
						/>
					</MDBCol>
					<MDBCol col={12} md={6} className="pb-md-0 pt-md-3">
						<Button
							type="institucional"
							to="https://www.feevale.br/s/conteudo/bed0518c-ad2c-4aeb-afb0-5ea2a7b43e3f/Regulamento_descontos_Escola_de_Aplica%c3%a7%c3%a3o_v1assinado.pdf"
							text="Confira o regulamento completo"
						/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
